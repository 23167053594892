//snips the date from the beginning of and ISO string
export function formatDate(date) {
  if (!(date instanceof Date)) {
    // Check if 'date' is not a Date object; if not, try parsing it
    date = new Date(date);
  }

  if (!isNaN(date.getTime())) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
  } else {
    return "Invalid Date";
  }
}

export const formatDateForDisplay = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    if (!isNaN(date.getTime())) {
    const options = {
        timeZone: "Europe/London",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
    } 
    else {
    return "Invalid Date";
    }
};

export const convertUtcToLocaleDateTime = (utcDateString) => {
  const utcDate = new Date(utcDateString);
  const offsetMilliseconds = utcDate.getTimezoneOffset() * 60 * 1000; // 60,000 milliseconds in a minute
  const localDate = new Date(utcDate.getTime() - offsetMilliseconds);

  // Format the local date in the "YYYY-MM-DDTHH:MM" format for datetime input
  const formattedDate = localDate.toISOString().slice(0, 16);

  return formattedDate;
};

export const calculateTimeDifferenceBetweenDatesInHours = (start, end) => {
  const timeDifferenceMs = new Date(end) - new Date(start);

  // Convert milliseconds to hours
  const hoursDifference = timeDifferenceMs / (1000 * 60 * 60);

  return hoursDifference;
};

//only works if you pass in ISO string
export function extractTime(dateTimeString) {
  if (isValidISOString(dateTimeString)) {
    const timePart = dateTimeString.split("T")[1];
    return timePart ? timePart : null;
  } else {
    return null;
  }
}

export function timeToDatetime(timeString) {
  const now = new Date(); // Get the current date and time
  const [hours, minutes] = timeString.split(":").map(Number); // Split timeString into hours and minutes
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Get the current month with leading zero padding
  const day = now.getDate().toString().padStart(2, "0"); // Get the current day with leading zero padding
  const paddedHours = hours.toString().padStart(2, "0"); // Ensure hours are padded with leading zeros
  const paddedMinutes = minutes.toString().padStart(2, "0"); // Ensure minutes are padded with leading zeros
  const dateString = `${now.getFullYear()}-${month}-${day}`; // Get the current date as a string
  const datetimeString = `${dateString}T${paddedHours}:${paddedMinutes}:00`; // Combine date and time components
  return datetimeString;
}

export function isValidISOString(isoString) {
  //regular expression for ISO 8601 date format
  const isoRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|([+-]\d{2}:\d{2}))?$/;

  return isoRegex.test(isoString);
}

export const calculateAgeInYears = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);

    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();

    // Check if the birthday has not occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }

    return age;
}

export const formatDateAndTimeForDisplay = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (!isNaN(date.getTime())) {
    const dateOptions = { timeZone: "Europe/London", day: "2-digit", month: "2-digit", year: "numeric" };
    const timeOptions = { timeZone: "Europe/London", hour: "2-digit", minute: "2-digit", hour12: false };

    const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-GB", timeOptions);

    return `${formattedDate} ${formattedTime}`;
  } else {
    return "Invalid Date";
  }
};