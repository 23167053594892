import { formatDateAndTimeForDisplay } from "../Utils/DateUtils";

export const DateTimeLabel = ({ displayValue, clickable = false }) => {
  if (displayValue !== "") {
    return (
      <div>
        {clickable ? (
          <label style={{ cursor: "pointer" }}>
            {formatDateAndTimeForDisplay(displayValue)}
          </label>
        ) : (
          <label>{formatDateAndTimeForDisplay(displayValue)}</label>
        )}
      </div>
    );
  } else {
    return <label></label>;
  }
};